
/** * This file must be the first included JS - right after DS.Config ** */
(function () {

  var addDataLayerFallback = function () {
    if (!Array.isArray(window.dataLayer)) {
      window.dataLayer = [];
      window.dataLayer[0] = {
        event: 'pmPushed',
        page: {
          brand: 'unknown',
          breadcrumb: 'unknown',
          cms: 'sitecore',
          id: '',
          ipStatus: 'internal',
          name: 'unknown',
          platform: 'danskespil_dk',
          referringPage: '',
          subsidiary: 'unknown',
          sysEnv: 'unknown',
          title: 'unknown',
          type: 'unknown',
          version: 'unknown'
        }
      };
    }
  };

  var setupSentry = function () {

    var sentryErrorExceptions = [];
    var sentryErrorTimestamps = [];

    window.Sentry.init({
      dsn: DS.Config.SENTRY_ENDPOINT,
      release: DS.Config.RELEASE,
      environment: DS.Config.SENTRY_ENV,
      attachStacktrace: true,
      ignoreErrors: [
        'execute code from a freed script' // A generic error we cannot get rid of properly because of our domain.name setting
      ],
      beforeSend: function (event, hint) {
        // Only report first 50 events on same page
        if (sentryErrorTimestamps.length >= 50) {
          return null;
        }
        // Only report first 5 events per rolling 60 minutes.
        if (sentryErrorTimestamps.filter(function (ts) { return (new Date()).getTime() - ts < 60 * 60 * 1000; }).length >= 5) {
          return null;
        }

        // Only report same error once per page load (Attempt per 20/4 to generel lower amount of Sentry event usage for recurring events)
        if (hint && hint.originalException && hint.originalException.message) {
          var ex = hint.originalException;
          var exceptionIdentifier = [ex.message, ex.name || '', ex.stack || ''].join('');

          if (sentryErrorExceptions.indexOf(exceptionIdentifier) !== -1) {
            // This exception already logged in this page load, so skip logging this again.
            return null;
          }

          sentryErrorExceptions.push(exceptionIdentifier);
        }

        var currentTime = (new Date()).getTime();
        sentryErrorTimestamps.push(currentTime);

        var duration = (currentTime - window.pageloadLocalEpoch) / 1000;
        event.tags = event.tags || {};
        event.tags['durationInSeconds'] = duration;
        event.tags['durationCategory'] = duration < 60 ? 'Below1Minute' : (duration < 60 * 60 ? 'Below1Hour' : 'Over1Hour');

        return event;
      }
    });

    window.Sentry.setTag('dsapplicationid', window.dsApplicationConfig && window.dsApplicationConfig.ApplicationId ? window.dsApplicationConfig.ApplicationId : 'EMPTY');

    var isIframe = false;
    var parentUrl = 'EMPTY';
    try {
      isIframe = window.self !== window.parent;
      if (isIframe) {
        parentUrl = window.parent.location.href;
      }
    } catch (e) {
      parentUrl = 'NO_RIGHTS';
      isIframe = true;
    }
    window.Sentry.setTag('is_iframe', isIframe);
    window.Sentry.setTag('parent_url', parentUrl);
  };

  /* Save local timestamp */
  window.pageloadLocalEpoch = window.pageloadLocalEpoch || (new Date()).getTime();

  /* Set domain name - important for iframed DSAPI integrations */
  var tagHtml = document.getElementsByTagName('html');
  window.sitecoreEnv = (!tagHtml.length || tagHtml[0].getAttribute('class').indexOf('mode-edit') > -1 || tagHtml[0].getAttribute('class').indexOf('mode-preview') > -1);
  if (!window.sitecoreEnv) {
    document.domain = 'danskespil.dk';
  }

  /* Setup sentry */
  const isHeadlessChrome = navigator.userAgent.indexOf('HeadlessChrome') > -1;
  if (DS.Config.SENTRY_ENABLED && window.Sentry && !window.sitecoreEnv && !isHeadlessChrome) {
    setupSentry();
  }

  /* Enforce HTTPS */
  if (DS.Config.ENFORCE_HTTPS && location.protocol == 'http:') {
    location.href = location.href.replace('http:', 'https:');
  }

  /* FIGURE OUT WHETHER OR NOT IT'S A TOUCHY */
  if ('ontouchstart' in window) {
    document.querySelector('html').className += ' has-touch';
  }

  /* Take log level from DS.Config
   * Log levels:
   *    log (1) < debug (2) < info (3) < warn (4) < error (5)
   *    0 means disabled
   *
  */

  /* Remove methods, that should be disabled according to log levels */
  window.console = window.console || {};

  /* Add empty console functions wrappers, if not supported by browser */
  var names = ['log', 'debug', 'info', 'warn', 'error', 'assert', 'dir', 'dirxml', 'group', 'groupEnd', 'time', 'timeEnd', 'count', 'trace', 'profile', 'profileEnd'];
  for (var i = 0; i < names.length; ++i) {
    if (typeof console[names[i]] === 'undefined') {
      window.console[names[i]] = function () { };
    }
  }


  /* Set a short but unique window name (used in EventClient cookies) */
  try {
    /* New format: dXXXXXXXX */
    if (!/^d\d{8}$/.test(name)) {
      window.name = 'd' + ('' + (new Date()).getTime()).slice(-8);
    }
  } catch (e) { }

  window.DS = DS || {};
  DS.ALL_PARTNERS_READ_ME = 'This DS namespace is only for DS internally use. DS vendors is not allowed to call these methods directly. Stay away! Please use DSAPI for integrations.';

  /* God dayum Webforms... */
  if (!(window.location.href.indexOf('velkommen/klage') > -1)) {
    window.WebForm_SaveScrollPositionSubmit = function () { }; // eslint-disable-line camelcase
  }

  window.WebForm_SaveScrollPositionOnSubmit = function () { }; // eslint-disable-line camelcase

  addDataLayerFallback();
})();
